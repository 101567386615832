<template>
    <article class="novelty-card">
        <div class="novelty-card-content">
            <NuxtLink
                no-prefetch
                :to="`/${$lang.routes.novelties}/${data.slug}`"
                class="novelty-card-content__image-wrapper"
                rel="follow"
            >
                <img
                    :src="data.image && data.image.url ? data.image.url : $assets.illustration.news"
                    class="novelty-card-content__image-wrapper__image"
                    :title="data.title"
                    :alt="data.title"
                />
            </NuxtLink>
            <div class="novelty-card-content-info">
                <NuxtLink
                    no-prefetch
                    :to="`/${$lang.routes.novelties}/${data.slug}`"
                    class="novelty-card-content-info__title"
                    rel="follow"
                >
                    <h3>{{ data.title }}</h3>
                </NuxtLink>
                <p class="novelty-card-content-info__description">{{ content }}</p>
            </div>
            <div class="novelty-card-content-footer">
                <div v-if="data.user" class="novelty-card-content-footer__author">
                    <NuxtLink no-prefetch :to="'/dashboard/' + data.user.id" rel="follow">
                        <MiscUserPic class="h-8 w-8" :user="data.user" />
                    </NuxtLink>

                    <div class="info">
                        <NuxtLink
                            no-prefetch
                            :to="'/dashboard/' + data.user.id"
                            rel="follow"
                            class="info_user"
                        >
                            {{ data.user.username }}
                        </NuxtLink>
                        <div v-if="data.user.role.name !== 'Usuario'">
                            <MiscBadge :text="data.user.role.name" />
                        </div>
                    </div>
                </div>
                <div class="novelty-card-content-footer__created">
                    <img :src="$assets.gray.createdAt" alt="tiempo restante" />
                    <p class="created-at-text">{{ $lang.components.cardNews.created_at }}</p>
                    <p class="date hidden md:block">{{ formatDate(data.created_at, ' ') }}</p>
                    <p class="date md:hidden">{{ formatDate(data.created_at, '/') }}</p>
                </div>
            </div>
        </div>
    </article>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import { useDateFunctions } from '~/composables/DateFunctions'
import type { Models } from '~/types/models'

export default defineComponent({
    name: 'CardNews',
    props: {
        data: {
            type: Object as PropType<Models.Novelty>,
            required: true,
        },
    },
    data() {
        const { formatDate } = useDateFunctions()
        return { formatDate, content: this.data.content?.replace(/<[^>]*>?/gm, ' ') || '' }
    },
})
</script>

<style lang="postcss" scoped>
.novelty-card {
    &-content {
        @apply grid grid-cols-12 gap-3 lg:gap-0;
        &__image-wrapper {
            @apply col-span-3 flex justify-center sm:col-span-2 sm:row-span-2 sm:block md:flex md:justify-start;
            &__image {
                @apply rounded-md sm:w-full md:w-full lg:w-11/12;
            }
        }
        &-info {
            @apply col-span-9 sm:col-span-10;
            &__title {
                @apply line-clamp-1 text-lg font-semibold text-gray-800 hover:underline;
            }
            &__description {
                @apply line-clamp-2 text-sm text-gray-800 md:line-clamp-3;
            }
        }
        &-footer {
            @apply col-span-12 flex items-center justify-between gap-1 sm:col-span-10 sm:col-start-3;
            &__author {
                @apply flex items-center gap-x-1;
                &__image {
                    @apply h-8 w-8 flex-none gap-x-2 rounded-full;
                    img {
                        @apply h-full w-full rounded-full object-cover;
                    }
                }
                .info {
                    @apply flex items-center gap-x-1;
                    &_user {
                        @apply overflow-hidden text-ellipsis whitespace-nowrap text-xs font-semibold hover:underline sm:text-sm;
                    }
                    &_rank {
                        @apply flex w-fit gap-1 self-center overflow-hidden text-ellipsis whitespace-nowrap rounded-xl border bg-[#EFEEF2] p-1 text-xs text-[#3D3D3D];
                    }
                }
            }
            &__created {
                @apply flex items-center gap-1 text-xs text-gray-800 sm:text-sm;
                img {
                    @apply h-4;
                }
                .created-at-text {
                    @apply hidden sm:block;
                }
                .date {
                    @apply font-semibold;
                }
            }
        }
    }
}
</style>
